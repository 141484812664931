import { raw } from '@mantou/gem';
import { extendIcons } from 'duoyun-ui/lib/icons';

function genIcon(d: string, ext = '') {
  return raw`
    <svg part="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 48 48" width="48px" fill="currentColor">
      <path d="M0 0h48v48H0z" fill="none" stroke="none"></path>
      <path d="${d}"></path>
      ${ext}
    </svg>
  `;
}

function genIconV2(d: string, ext = '', viewBox = '0 0 960 960') {
  return raw`
    <svg part="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="${viewBox}" width="48px" fill="currentColor">
      <path d="M0 0h960v960H0z" fill="none" stroke="none"></path>
      <path d="${d}"></path>
      ${ext}
    </svg>
  `;
}

// https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Symbols
export const [icons] = extendIcons({
  screenRecord: genIconV2(
    'M159 813q-37-51-58-110.5T80 576q0-67 20.5-127T158 339l43 43q-29 42-45 91t-16 103q0 54 16.5 103.5T202 771l-43 42Zm321 163q-66 0-126.5-20.5T243 898l43-43q42 29 91 45t103 16q54 0 103-16t91-45l43 43q-50 37-110 57.5T480 976Zm322-162-43-43q29-42 45-91.5T820 576q0-54-16-103t-45-91l43-43q37 50 57.5 110T880 576q0 67-20.5 127T802 814ZM285 297l-43-43q51-37 111-57.5T480 176q67 0 127 21t111 58l-43 43q-42-29-91-45.5T480 236q-54 0-103.5 16T285 297Zm195 469q-79 0-134.5-55.5T290 576q0-79 55.5-134.5T480 386q79 0 134.5 55.5T670 576q0 79-55.5 134.5T480 766Z',
    '',
    '0 96 960 960',
  ),
  menu: genIconV2(
    'M150 816q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 756h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 816H150Zm0-210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 546h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 606H150Zm0-210q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T150 336h660q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810 396H150Z',
    '',
    '0 96 960 960',
  ),
  notAllowed: genIconV2(
    'M310 603h340q12.75 0 21.375-8.675 8.625-8.676 8.625-21.5 0-12.825-8.625-21.325T650 543H310q-12.75 0-21.375 8.675-8.625 8.676-8.625 21.5 0 12.825 8.625 21.325T310 603Zm170.266 373q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80 658.319 80 575.5q0-82.819 31.5-155.659Q143 347 197.5 293t127.341-85.5Q397.681 176 480.5 176q82.819 0 155.659 31.5Q709 239 763 293t85.5 127Q880 493 880 575.734q0 82.734-31.5 155.5T763 858.316q-54 54.316-127 86Q563 976 480.266 976Zm.234-60Q622 916 721 816.5t99-241Q820 434 721.188 335 622.375 236 480 236q-141 0-240.5 98.812Q140 433.625 140 576q0 141 99.5 240.5t241 99.5Zm-.5-340Z',
  ),
  more: genIcon(
    'M10.4 26.4q-1 0-1.7-.7T8 24q0-1 .7-1.7t1.7-.7q1 0 1.7.7t.7 1.7q0 1-.7 1.7t-1.7.7Zm13.6 0q-1 0-1.7-.7t-.7-1.7q0-1 .7-1.7t1.7-.7q1 0 1.7.7t.7 1.7q0 1-.7 1.7t-1.7.7Zm13.6 0q-1 0-1.7-.7t-.7-1.7q0-1 .7-1.7t1.7-.7q1 0 1.7.7T40 24q0 1-.7 1.7t-1.7.7Z',
  ),
  help: genIcon(
    'M24.2 35.65q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35t-1.35-.55q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55ZM24.15 15q1.7 0 2.75.925t1.05 2.375q0 1-.6 1.975-.6.975-1.95 2.125-1.3 1.15-2.075 2.425-.775 1.275-.775 2.325 0 .55.425.875.425.325.975.325.6 0 1-.4t.5-1q.15-1 .675-1.775.525-.775 1.625-1.675 1.5-1.25 2.175-2.5.675-1.25.675-2.8 0-2.65-1.725-4.25t-4.575-1.6q-1.9 0-3.5.75t-2.65 2.2q-.4.55-.325 1.125.075.575.475.875.55.4 1.175.25.625-.15 1.025-.7.65-.9 1.575-1.375Q23 15 24.15 15ZM24 44q-4.2 0-7.85-1.525Q12.5 40.95 9.8 38.25q-2.7-2.7-4.25-6.35Q4 28.25 4 24q0-4.2 1.55-7.85Q7.1 12.5 9.8 9.8q2.7-2.7 6.35-4.25Q19.8 4 24 4q4.15 0 7.8 1.55 3.65 1.55 6.35 4.25 2.7 2.7 4.275 6.35Q44 19.8 44 24q0 4.25-1.575 7.9-1.575 3.65-4.275 6.35-2.7 2.7-6.35 4.225Q28.15 44 24 44Zm0-20.55ZM24 41q7.05 0 12.025-5Q41 31 41 24t-4.975-12Q31.05 7 24 7q-7.15 0-12.075 5Q7 17 7 24t4.925 12Q16.85 41 24 41Z',
  ),
  expand: genIcon(
    'M24 30.15q-.3 0-.55-.1-.25-.1-.5-.35l-9.9-9.9q-.4-.4-.375-1.075.025-.675.425-1.075.5-.5 1.075-.425.575.075 1.025.475l8.8 8.8 8.8-8.8q.4-.4 1.075-.45.675-.05 1.075.45.5.4.425 1.05-.075.65-.475 1.1l-9.85 9.85q-.25.25-.5.35-.25.1-.55.1Z',
  ),
  left: genIcon(
    'm26.95 34.9-9.9-9.9q-.25-.25-.35-.5-.1-.25-.1-.55 0-.3.1-.55.1-.25.35-.5L27 12.95q.45-.45 1.075-.45t1.075.45q.45.45.425 1.1-.025.65-.475 1.1l-8.8 8.8 8.85 8.85q.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.1.45-.65 0-1.1-.45Z',
  ),
  right: genIcon(
    'M17.7 34.9q-.4-.5-.425-1.1-.025-.6.425-1.05l8.8-8.8-8.85-8.85q-.4-.4-.375-1.075.025-.675.425-1.075.5-.5 1.075-.475.575.025 1.025.475l9.95 9.95q.25.25.35.5.1.25.1.55 0 .3-.1.55-.1.25-.35.5l-9.9 9.9q-.45.45-1.05.425-.6-.025-1.1-.425Z',
  ),
  add: genIcon(
    'M24 38q-.65 0-1.075-.425-.425-.425-.425-1.075v-11h-11q-.65 0-1.075-.425Q10 24.65 10 24q0-.65.425-1.075.425-.425 1.075-.425h11v-11q0-.65.425-1.075Q23.35 10 24 10q.65 0 1.075.425.425.425.425 1.075v11h11q.65 0 1.075.425Q38 23.35 38 24q0 .65-.425 1.075-.425.425-1.075.425h-11v11q0 .65-.425 1.075Q24.65 38 24 38Z',
  ),
  close: genIcon(
    'M24 26.1 13.5 36.6q-.45.45-1.05.45-.6 0-1.05-.45-.45-.45-.45-1.05 0-.6.45-1.05L21.9 24 11.4 13.5q-.45-.45-.45-1.05 0-.6.45-1.05.45-.45 1.05-.45.6 0 1.05.45L24 21.9l10.5-10.5q.45-.45 1.05-.45.6 0 1.05.45.45.45.45 1.05 0 .6-.45 1.05L26.1 24l10.5 10.5q.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.05.45-.6 0-1.05-.45Z',
  ),
  forward: genIcon(
    'M22.95 38.9q-.4-.4-.4-1.05t.4-1.05l11.3-11.3H9.5q-.65 0-1.075-.425Q8 24.65 8 24q0-.65.425-1.075Q8.85 22.5 9.5 22.5h24.75l-11.3-11.3q-.4-.4-.4-1.075 0-.675.4-1.075.4-.4 1.05-.4t1.05.4l13.9 13.9q.25.25.35.5.1.25.1.55 0 .25-.1.525t-.35.525l-13.9 13.9q-.4.4-1.05.375-.65-.025-1.05-.425Z',
  ),
  date: genIcon(
    'M29.85 37q-2.05 0-3.45-1.4-1.4-1.4-1.4-3.45 0-2.05 1.4-3.45 1.4-1.4 3.45-1.4 2.05 0 3.45 1.4 1.4 1.4 1.4 3.45 0 2.05-1.4 3.45-1.4 1.4-3.45 1.4ZM9 44q-1.2 0-2.1-.9Q6 42.2 6 41V10q0-1.2.9-2.1Q7.8 7 9 7h3.25V5.6q0-.7.45-1.15.45-.45 1.15-.45t1.175.45q.475.45.475 1.15V7h17V5.6q0-.7.45-1.15Q33.4 4 34.1 4t1.175.45q.475.45.475 1.15V7H39q1.2 0 2.1.9.9.9.9 2.1v31q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V19.5H9V41Zm0-24.5h30V10H9Zm0 0V10v6.5Z',
  ),
  schedule: genIcon(
    'M30.3 32.55q.45.45 1.05.45.6 0 1.1-.5.5-.5.525-1.15.025-.65-.475-1.1l-6.85-6.85v-8.6q0-.6-.425-1.025-.425-.425-1.075-.425-.65 0-1.075.425-.425.425-.425 1.075V24q0 .3.1.55.1.25.3.5ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 24t1.575-7.75q1.575-3.65 4.3-6.375 2.725-2.725 6.375-4.3Q19.9 4 24 4t7.75 1.575q3.65 1.575 6.375 4.3 2.725 2.725 4.3 6.375Q44 19.9 44 24t-1.575 7.75q-1.575 3.65-4.3 6.375-2.725 2.725-6.375 4.3Q28.1 44 24 44Zm0-20Zm0 17q7 0 12-5t5-12q0-7-5-12T24 7q-7 0-12 5T7 24q0 7 5 12t12 5Z',
  ),
  check: genIcon(
    'M18.9 35.1q-.3 0-.55-.1-.25-.1-.5-.35L8.8 25.6q-.45-.45-.45-1.1 0-.65.45-1.1.45-.45 1.05-.45.6 0 1.05.45l8 8 18.15-18.15q.45-.45 1.075-.45t1.075.45q.45.45.45 1.075T39.2 15.4L19.95 34.65q-.25.25-.5.35-.25.1-.55.1Z',
  ),
  search: genIcon(
    'M38.7 40.85 26.65 28.8q-1.5 1.3-3.5 2.025-2 .725-4.25.725-5.4 0-9.15-3.75T6 18.75q0-5.3 3.75-9.05 3.75-3.75 9.1-3.75 5.3 0 9.025 3.75 3.725 3.75 3.725 9.05 0 2.15-.7 4.15-.7 2-2.1 3.75L40.95 38.7q.45.4.45 1.025 0 .625-.5 1.125-.45.45-1.1.45-.65 0-1.1-.45Zm-19.85-12.3q4.05 0 6.9-2.875Q28.6 22.8 28.6 18.75t-2.85-6.925Q22.9 8.95 18.85 8.95q-4.1 0-6.975 2.875T9 18.75q0 4.05 2.875 6.925t6.975 2.875Z',
  ),
  person: genIcon(
    'M24 23.95q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1ZM37 40H11q-1.25 0-2.125-.875T8 37v-1.7q0-1.9.95-3.25T11.4 30q3.35-1.5 6.425-2.25Q20.9 27 24 27q3.1 0 6.15.775 3.05.775 6.4 2.225 1.55.7 2.5 2.05.95 1.35.95 3.25V37q0 1.25-.875 2.125T37 40Zm-26-3h26v-1.7q0-.8-.475-1.525-.475-.725-1.175-1.075-3.2-1.55-5.85-2.125Q26.85 30 24 30t-5.55.575q-2.7.575-5.85 2.125-.7.35-1.15 1.075Q11 34.5 11 35.3Zm13-16.05q1.95 0 3.225-1.275Q28.5 18.4 28.5 16.45q0-1.95-1.275-3.225Q25.95 11.95 24 11.95q-1.95 0-3.225 1.275Q19.5 14.5 19.5 16.45q0 1.95 1.275 3.225Q22.05 20.95 24 20.95Zm0-4.5ZM24 37Z',
  ),
  addPerson: genIcon(
    'M38 28q-.65 0-1.075-.425-.425-.425-.425-1.075v-5h-5q-.65 0-1.075-.425Q30 20.65 30 20q0-.65.425-1.075.425-.425 1.075-.425h5v-5q0-.65.425-1.075Q37.35 12 38 12q.65 0 1.075.425.425.425.425 1.075v5h5q.65 0 1.075.425Q46 19.35 46 20q0 .65-.425 1.075-.425.425-1.075.425h-5v5q0 .65-.425 1.075Q38.65 28 38 28Zm-20-4.05q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1ZM3.5 40q-.65 0-1.075-.425Q2 39.15 2 38.5v-3.2q0-1.75.9-3.175Q3.8 30.7 5.4 30q3.75-1.65 6.675-2.325Q15 27 18 27t5.925.675Q26.85 28.35 30.55 30q1.6.75 2.525 2.15.925 1.4.925 3.15v3.2q0 .65-.425 1.075Q33.15 40 32.5 40ZM5 37h26v-1.7q0-.8-.4-1.525-.4-.725-1.25-1.075-3.5-1.7-5.975-2.2Q20.9 30 18 30t-5.375.525Q10.15 31.05 6.6 32.7q-.75.35-1.175 1.075Q5 34.5 5 35.3Zm13-16.05q1.95 0 3.225-1.275Q22.5 18.4 22.5 16.45q0-1.95-1.275-3.225Q19.95 11.95 18 11.95q-1.95 0-3.225 1.275Q13.5 14.5 13.5 16.45q0 1.95 1.275 3.225Q16.05 20.95 18 20.95Zm0-4.5ZM18 30Z',
  ),
  group: genIcon(
    'M3.4 40q-.65 0-1.075-.425Q1.9 39.15 1.9 38.5v-3.2q0-1.75.9-3.175Q3.7 30.7 5.3 30q3.65-1.6 6.575-2.3Q14.8 27 17.9 27q3.1 0 6 .7t6.55 2.3q1.6.7 2.525 2.125.925 1.425.925 3.175v3.2q0 .65-.425 1.075Q33.05 40 32.4 40Zm32.4 0q.5-.1.8-.525.3-.425.3-1.075v-3.1q0-3.15-1.6-5.175t-4.2-3.275q3.45.4 6.5 1.175t4.95 1.775q1.65.95 2.6 2.35.95 1.4.95 3.15v3.2q0 .65-.425 1.075Q45.25 40 44.6 40ZM17.9 23.95q-3.3 0-5.4-2.1-2.1-2.1-2.1-5.4 0-3.3 2.1-5.4 2.1-2.1 5.4-2.1 3.3 0 5.4 2.1 2.1 2.1 2.1 5.4 0 3.3-2.1 5.4-2.1 2.1-5.4 2.1Zm18-7.5q0 3.3-2.1 5.4-2.1 2.1-5.4 2.1-.55 0-1.225-.075T25.95 23.6q1.2-1.25 1.825-3.075.625-1.825.625-4.075t-.625-3.975Q27.15 10.75 25.95 9.3q.55-.15 1.225-.25t1.225-.1q3.3 0 5.4 2.1 2.1 2.1 2.1 5.4ZM4.9 37h26v-1.7q0-.8-.475-1.55T29.25 32.7q-3.6-1.6-6.05-2.15-2.45-.55-5.3-.55-2.85 0-5.325.55T6.5 32.7q-.7.3-1.15 1.05-.45.75-.45 1.55Zm13-16.05q1.95 0 3.225-1.275Q22.4 18.4 22.4 16.45q0-1.95-1.275-3.225Q19.85 11.95 17.9 11.95q-1.95 0-3.225 1.275Q13.4 14.5 13.4 16.45q0 1.95 1.275 3.225Q15.95 20.95 17.9 20.95Zm0-4.5Zm0 13.55Z',
  ),
  empty: genIcon(
    'M22.9 43.95q-3.95-.2-7.4-1.85-3.45-1.65-6-4.325Q6.95 35.1 5.475 31.55 4 28 4 24q0-4.15 1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4q7.45 0 12.95 4.7 5.5 4.7 6.75 11.8h-3.05q-.85-4.2-3.55-7.5-2.7-3.3-6.75-4.95v.9q0 1.75-1.2 3.05-1.2 1.3-2.95 1.3h-4.35v4.35q0 .85-.675 1.4-.675.55-1.525.55H15.5V24H21v6.25h-3.35l-10.2-10.2q-.25 1-.35 1.975Q7 23 7 24q0 6.75 4.55 11.65t11.35 5.3Zm18.55-2.4-5.65-5.6q-1.05.75-2.275 1.15-1.225.4-2.525.4-3.55 0-6.025-2.475Q22.5 32.55 22.5 29q0-3.55 2.475-6.025Q27.45 20.5 31 20.5q3.55 0 6.025 2.475Q39.5 25.45 39.5 29q0 1.3-.425 2.525-.425 1.225-1.125 2.325l5.6 5.6q.45.45.475 1.05.025.6-.425 1.1-.45.45-1.075.425Q41.9 42 41.45 41.55ZM31 34.5q2.3 0 3.9-1.6t1.6-3.9q0-2.3-1.6-3.9T31 23.5q-2.3 0-3.9 1.6T25.5 29q0 2.3 1.6 3.9t3.9 1.6Z',
  ),
  favorite: genIcon(
    'm21.95 40.2-2.65-2.45Q13.1 32 8.55 26.775T4 15.85q0-4.5 3.025-7.525Q10.05 5.3 14.5 5.3q2.55 0 5.05 1.225T24 10.55q2.2-2.8 4.55-4.025Q30.9 5.3 33.5 5.3q4.45 0 7.475 3.025Q44 11.35 44 15.85q0 5.7-4.55 10.925Q34.9 32 28.7 37.75l-2.65 2.45q-.85.8-2.05.8-1.2 0-2.05-.8Zm.75-26.35q-1.35-2.45-3.55-4-2.2-1.55-4.65-1.55-3.3 0-5.4 2.125Q7 12.55 7 15.85q0 2.9 1.95 6.075Q10.9 25.1 13.6 28.1t5.6 5.575Q22.1 36.25 24 38q1.9-1.7 4.8-4.3 2.9-2.6 5.6-5.625 2.7-3.025 4.65-6.2Q41 18.7 41 15.85q0-3.3-2.125-5.425T33.5 8.3q-2.5 0-4.675 1.525T25.2 13.85q-.25.4-.55.575-.3.175-.7.175-.4 0-.725-.175-.325-.175-.525-.575Zm1.3 9.3Z',
  ),
  favorited: genIcon(
    'm21.95 40.2-2.65-2.45Q13.1 32 8.55 26.775T4 15.85q0-4.5 3.025-7.525Q10.05 5.3 14.5 5.3q2.55 0 5.05 1.225T24 10.55q2.2-2.8 4.55-4.025Q30.9 5.3 33.5 5.3q4.45 0 7.475 3.025Q44 11.35 44 15.85q0 5.7-4.55 10.925Q34.9 32 28.7 37.75l-2.65 2.45q-.85.8-2.05.8-1.2 0-2.05-.8Z',
  ),
  received: genIcon(
    'M11.5 40q-.65 0-1.075-.425Q10 39.15 10 38.5V21.7q0-.65.425-1.075.425-.425 1.075-.425.65 0 1.075.425Q13 21.05 13 21.7v13.2l23.85-23.85q.4-.4 1.05-.425.65-.025 1.05.425.45.4.45 1.05t-.45 1.1L15.15 37H28.3q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q28.95 40 28.3 40Z',
  ),
  like: genIcon(
    'M35.8 42H13.6V16.4L27.5 2l1.95 1.55q.3.25.45.7.15.45.15 1.1v.5L27.8 16.4h14.95q1.2 0 2.1.9.9.9.9 2.1v4.1q0 .35.075.725t-.075.725l-6.3 14.5q-.45 1.05-1.475 1.8Q36.95 42 35.8 42Zm-19.2-3h19.85l6.3-14.95V19.4H24.1l2.65-12.45-10.15 10.7Zm0-21.35V39Zm-3-1.25v3H6.95V39h6.65v3H3.95V16.4Z',
  ),
  likeSolid: genIcon(
    'M13.6 42V16.4L24.85 4.5q.7-.7 1.625-.825t1.775.375q.85.5 1.275 1.375.425.875.225 1.825L27.8 16.4h14.95q1.2 0 2.1.9.9.9.9 2.1v4.1q0 .35.075.725t-.075.725l-6.3 14.5q-.45 1.05-1.475 1.8Q36.95 42 35.8 42ZM6.95 42q-1.25 0-2.125-.875T3.95 39V19.4q0-1.25.875-2.125T6.95 16.4h6.65v3H6.95V39h6.65v3Zm9.65-3V17.65 39Z',
  ),
  unlike: genIcon(
    'M5.25 31.6q-1.2 0-2.1-.9-.9-.9-.9-2.1v-4.1q0-.35-.075-.725t.075-.725l6.3-14.5Q9 7.5 10.025 6.75 11.05 6 12.2 6h22.2v25.6L23.15 43.5q-.7.7-1.625.825t-1.775-.375q-.85-.5-1.275-1.375-.425-.875-.225-1.825l1.95-9.15Zm26.15-1.25V9H11.55l-6.3 14.95v4.65H23.9l-2.65 12.45ZM41.05 6q1.25 0 2.125.875T44.05 9v19.6q0 1.25-.875 2.125t-2.125.875H34.4v-3h6.65V9H34.4V6ZM31.4 9v21.35V9Z',
  ),
  unlikeSolid: genIcon(
    'M5.25 31.6q-1.2 0-2.1-.9-.9-.9-.9-2.1v-4.1q0-.35-.075-.725t.075-.725l6.3-14.5Q9 7.5 10.025 6.75 11.05 6 12.2 6h22.2v25.6L23.15 43.5q-.7.7-1.625.825t-1.775-.375q-.85-.5-1.275-1.375-.425-.875-.225-1.825l1.95-9.15ZM41.05 6q1.25 0 2.125.875T44.05 9v19.6q0 1.25-.875 2.125t-2.125.875H34.4v-3h6.65V9H34.4V6ZM31.4 9v21.35V9Z',
  ),
  game: genIcon(
    `M9.45 40q-3.15 0-5.2-2.2-2.05-2.2-2.15-5.15 0-.4.05-.9t.15-.9l4.2-16.8q.7-2.65 2.875-4.35Q11.55 8 14.25 8h19.5q2.7 0 4.875 1.7t2.875 4.35l4.2 16.8q.1.45.175.925.075.475.075.925 0 3-2.125 5.15Q41.7 40 38.55 40q-2.1 0-3.9-1.1-1.8-1.1-2.7-3l-1.4-2.9q-.25-.55-.725-.775Q29.35 32 28.75 32h-9.5q-.6 0-1.075.225T17.45 33l-1.4 2.9q-.9 1.9-2.7 3-1.8 1.1-3.9 1.1Zm.15-3q1.1 0 2.125-.575T13.4 34.6l1.4-2.85q.65-1.3 1.825-2.025Q17.8 29 19.25 29h9.5q1.4 0 2.6.75 1.2.75 1.9 2l1.4 2.85q.65 1.25 1.675 1.825T38.45 37q1.7 0 3.05-1.175 1.35-1.175 1.4-3.075 0-.25-.025-.575-.025-.325-.125-.625l-4.2-16.75q-.4-1.7-1.725-2.75Q35.5 11 33.75 11h-19.5q-1.75 0-3.075 1.05Q9.85 13.1 9.45 14.8l-4.2 16.75q-.05.2-.15 1.15 0 1.9 1.4 3.1Q7.9 37 9.6 37ZM27 21.5q-.6 0-1.05-.45-.45-.45-.45-1.1 0-.6.45-1.025.45-.425 1.1-.425.6 0 1.025.425.425.425.425 1.075 0 .6-.425 1.05-.425.45-1.075.45Zm4-4q-.6 0-1.05-.45-.45-.45-.45-1.1 0-.6.45-1.025.45-.425 1.1-.425.6 0 1.025.425.425.425.425 1.075 0 .6-.425 1.05-.425.45-1.075.45Zm0 8q-.6 0-1.05-.45-.45-.45-.45-1.1 0-.6.45-1.025.45-.425 1.1-.425.6 0 1.025.425.425.425.425 1.075 0 .6-.425 1.05-.425.45-1.075.45Zm4-4q-.6 0-1.05-.45-.45-.45-.45-1.1 0-.6.45-1.025.45-.425 1.1-.425.6 0 1.025.425.425.425.425 1.075 0 .6-.425 1.05-.425.45-1.075.45Zm-18 3.25q-.5 0-.875-.375t-.375-.875v-2.25H13.5q-.5 0-.875-.375T12.25 20q0-.5.375-.875t.875-.375h2.25V16.5q0-.5.375-.875T17 15.25q.5 0 .875.375t.375.875v2.25h2.25q.5 0 .875.375t.375.875q0 .5-.375.875t-.875.375h-2.25v2.25q0 .5-.375.875T17 24.75Z`,
  ),
  share: genIcon(
    `M6 37.75v-27.5q0-.8.675-1.225Q7.35 8.6 8.1 8.9l32.6 13.7q.9.4.9 1.4 0 1-.9 1.4L8.1 39.1q-.75.3-1.425-.125T6 37.75Zm3-2.4L36.2 24 9 12.5v8.4L21.1 24 9 27ZM9 24V12.5v22.85Z`,
  ),
  openNewWindow: genIcon(
    `M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h12.45q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q22.1 9 21.45 9H9v30h30V26.55q0-.65.425-1.075.425-.425 1.075-.425.65 0 1.075.425Q42 25.9 42 26.55V39q0 1.2-.9 2.1-.9.9-2.1.9Zm9.05-12.05q-.4-.45-.425-1.05-.025-.6.425-1.05L36.9 9h-9.45q-.65 0-1.075-.425-.425-.425-.425-1.075 0-.65.425-1.075Q26.8 6 27.45 6H40.5q.65 0 1.075.425Q42 6.85 42 7.5v13.05q0 .65-.425 1.075-.425.425-1.075.425-.65 0-1.075-.425Q39 21.2 39 20.55v-9.4L20.15 30q-.4.4-1.025.4-.625 0-1.075-.45Z`,
  ),
  edit: genIcon(
    `m40.5 29.05-3.55-3.55 1.45-1.45q.4-.4 1.05-.4t1.05.4l1.45 1.45q.4.4.4 1.05t-.4 1.05ZM24 42v-3.55l10.8-10.8 3.55 3.55L27.55 42ZM6 31.5v-3h15v3Zm0-8.25v-3h23.5v3ZM6 15v-3h23.5v3Z`,
  ),
  mic: genIcon(
    `M24 26.85q-2.15 0-3.6-1.55-1.45-1.55-1.45-3.75V9q0-2.1 1.475-3.55Q21.9 4 24 4t3.575 1.45Q29.05 6.9 29.05 9v12.55q0 2.2-1.45 3.75-1.45 1.55-3.6 1.55Zm0-11.4ZM24 42q-.65 0-1.075-.425-.425-.425-.425-1.075v-5.3q-4.9-.55-8.375-3.925Q10.65 27.9 10.1 23.15q-.1-.65.375-1.125t1.175-.475q.55 0 .95.4.4.4.5.95.55 4.05 3.65 6.725Q19.85 32.3 24 32.3t7.25-2.675q3.1-2.675 3.65-6.725.1-.55.525-.95.425-.4.975-.4.7 0 1.15.475.45.475.35 1.125-.55 4.75-4.025 8.125Q30.4 34.65 25.5 35.2v5.3q0 .65-.425 1.075Q24.65 42 24 42Zm0-18.15q.9 0 1.475-.675.575-.675.575-1.625V9q0-.85-.6-1.425Q24.85 7 24 7t-1.45.575q-.6.575-.6 1.425v12.55q0 .95.575 1.625T24 23.85Z`,
  ),
  micOff: genIcon(
    `m34.3 29.95-2.15-2.15q.85-1.05 1.325-2.3.475-1.25.625-2.6.1-.55.525-.95.425-.4.975-.4.7 0 1.15.475.45.475.35 1.125-.25 1.85-.95 3.6t-1.85 3.2Zm-6.4-6.4-2.65-2.6V9.05q0-.85-.6-1.45T23.2 7q-.85 0-1.45.6t-.6 1.45v7.75l-3-3V9.05q0-2.1 1.475-3.575T23.2 4q2.1 0 3.575 1.475T28.25 9.05v12.5q0 .4-.075 1t-.275 1Zm-4.85-4.85Zm17.5 25.6L2.75 6.5q-.4-.4-.4-.975t.4-.975q.4-.4.95-.4.55 0 .95.4L42.5 42.4q.4.4.4.95 0 .55-.4.95-.4.4-.975.4t-.975-.4ZM21.7 40.5v-5.3q-4.9-.55-8.375-3.925Q9.85 27.9 9.3 23.15q-.1-.65.375-1.125t1.175-.475q.55 0 .95.4.4.4.5.95.55 4.05 3.65 6.725 3.1 2.675 7.25 2.675 1.9 0 3.65-.625t3.2-1.725l2.15 2.15q-1.55 1.3-3.45 2.075-1.9.775-4.05 1.025v5.3q0 .65-.425 1.075Q23.85 42 23.2 42q-.65 0-1.075-.425-.425-.425-.425-1.075Z`,
  ),
  volume: genIcon(
    `M21.16 6.26l-1.41-1.41-3.56 3.55 1.41 1.41s3.45-3.52 3.56-3.55zM11 2h2v5h-2zM6.4 9.81 7.81 8.4 4.26 4.84 2.84 6.26c.11.03 3.56 3.55 3.56 3.55z`,
  ),
  chat: genIcon(
    `M15.3 21.85q.85 0 1.425-.575.575-.575.575-1.425 0-.85-.575-1.425-.575-.575-1.425-.575-.85 0-1.425.575Q13.3 19 13.3 19.85q0 .85.575 1.425.575.575 1.425.575Zm8.85 0q.85 0 1.425-.575.575-.575.575-1.425 0-.85-.575-1.425-.575-.575-1.425-.575-.85 0-1.425.575-.575.575-.575 1.425 0 .85.575 1.425.575.575 1.425.575Zm8.5 0q.85 0 1.425-.575.575-.575.575-1.425 0-.85-.575-1.425-.575-.575-1.425-.575-.85 0-1.425.575-.575.575-.575 1.425 0 .85.575 1.425.575.575 1.425.575ZM4 40.4V7q0-1.15.9-2.075Q5.8 4 7 4h34q1.15 0 2.075.925Q44 5.85 44 7v26q0 1.15-.925 2.075Q42.15 36 41 36H12l-5.45 5.45q-.7.7-1.625.325Q4 41.4 4 40.4Zm3-3.65L10.75 33H41V7H7ZM7 7v29.75Z`,
  ),
});
